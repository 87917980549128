@font-face {
  font-family: 'Metric thin';
  src: url('./fonts/MetricWeb-Thin.woff2');
  font-style: normal;
  font-weight: 200
}

@font-face {
  font-family: 'Metric light';
  src: url('./fonts/MetricWeb-Light.woff2');
  font-style: normal;
  font-weight: 300
}

@font-face {
  font-family: 'Metric';
  src: url('./fonts/MetricWeb-Regular.woff2');
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: 'Metric medium';
  src: url('./fonts/MetricWeb-Medium.woff2');
  font-style: normal;
  font-weight: 500
}

@font-face {
  font-family: 'Metric semibold';
  src: url('./fonts/MetricWeb-Semibold.woff2');
  font-style: normal;
  font-weight: 600
}

@font-face {
  font-family: 'Metric bold';
  src: url('./fonts/MetricWeb-Bold.woff2');
  font-style: normal;
  font-weight: 700
}

.grid-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 2fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "video map"
    "stationInfo speed";
  height: 100vh;
  width: 100vw;
  background-color: #16161d;
  color: #fff;
  font-family: 'Metric';
  overflow: hidden;
}

.video { 
  grid-area: video;
  display: flex;
  align-items: center;
  background-color: black;
}

.video > video {
  width: 100%;
  height: 100%;
}

.video > video:focus {
  outline: none;
}

.map { 
  grid-area: map; 
}

.speed { 
  grid-area: speed; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.speed > div {
  height: 50%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.stationInfo {
  grid-area: stationInfo;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.stationInfo > .logo {
  align-items: center;
  justify-content: center;
  width: 10em;
  margin: 0 2em;
}

.stationInfo > .logo > img {
  width: 100%;
  height: auto;
}

.stationInfo .nextStopLabel {
  font-family: 'Metric light';
  text-transform: uppercase;
}

.stationInfo .nextStop {
  font-family: 'Metric semibold';
  font-size: 4em;
  margin-top: -0.2em;
}

.stationInfo .nextStopNotes {
  font-family: 'Metric bold';
  background-color: #FFB81C;
  color: #000;
  padding: 5px 10px;
  margin-top: 0.5em;
  display: inline-block;;
  text-transform: uppercase;
  font-size: smaller;
}